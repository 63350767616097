import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import InlineImage from "../../../../src/components/inlineImage";
import * as React from 'react';
export default {
  Link,
  getImage,
  GatsbyImage,
  InlineImage,
  React
};