import React from "react"
import styled from "styled-components"

const InlineImage = ({ className, children }) => (
  <div className={className}>
      <div className="wrapper">{children}</div>
  </div>
)

export default styled(InlineImage)`
  text-align: center;

  .wrapper {
    margin: 0;
    display: inline-block;
    width: 200px;

    @media all and (min-width: 481px) {
      float: right;
      margin-left: 40px;
    }
  }

  .gatsby-image-wrapper {
    border-radius: 100%;
  }
`
